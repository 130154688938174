import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import "./styles/accessinclusion.scss";
import "./styles/fontawesome/scss/fontawesome.scss"
import "./styles/cookieConsent.css";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import * as microsoftTeams from "@microsoft/teams-js";
// import CookieIcon from '@material-ui/core/Cookie';

export default function CookieConsent() {
    const [cookies, setCookie] = useCookies(['wbcAnalyticsCC', 'wbcAdsCC']);
    const [insideMSTeams, setInsideMSTeams] = useState(undefined)
    // const [temporaryBannerDisable, setTemporaryBannerDisable] = useState(true)
    const temporaryBannerDisable = false;
    
    function iniFrame() {
        var withiniframe = false;
        if (window.location !== window.parent.location) {
            // The page is in an iFrames
            // console.log("The page is in an iFrame");
            withiniframe = true;
        }
        else {
            // The page is not in an iFrame
            // console.log("The page is not in an iFrame");
            withiniframe = false;
        }
        return withiniframe;
    }

    React.useEffect(() => {
        if (insideMSTeams !== undefined) {
            return;
        }
        try {
            microsoftTeams.app.initialize().then(() => {
                // console.log("Detected inside msteams in initialize function")
                window.insideMSTeams = true
                setInsideMSTeams(true)
                microsoftTeams.app.getContext().then((context) => {
                window.msHost = context.app.host
                // console.log("Detected msHost = ", window.msHost);
                // console.log("Detected clientType = ", context.app.host.clientType);
                })
            }, () => {
                window.insideMSTeams = false
                setInsideMSTeams(false)
            })
            setTimeout(() => {
            if (window.insideMSTeams === undefined) {
                window.insideMSTeams = false
                setInsideMSTeams(false)
            }
            }, 3000)
        } catch {
            window.insideMSTeams = false
            setInsideMSTeams(false)
        }
    }, [insideMSTeams])

    function cookiesAccepted() {
        var d = new Date(0);
        // Six months per regulations
        d.setUTCMilliseconds(Date.now()+15780000000);
        setCookie('wbcAnalyticsCC', true, { path: "/", expires: d, sameSite: "strict" })
        setCookie('wbcAdsCC', true, { path: "/", expires: d, sameSite: "strict" })
        window.wbcAnalyticsCC = true
        window.wbcAdsCC = true
    }

    function onlyAnalyticsCookiesAccepted() {
        var d = new Date(0);
        // Six months per regulations
        d.setUTCMilliseconds(Date.now()+15780000000);
        setCookie('wbcAnalyticsCC', true, { path: "/", expires: d, sameSite: "strict" })
        setCookie('wbcAdsCC', false, { path: "/", expires: d, sameSite: "strict" })
        window.wbcAnalyticsCC = true
        window.wbcAdsCC = false
    }

    function cookiesPanelClosed() {
        var d = new Date(0);
        // Show again after 2 days
        d.setUTCMilliseconds(Date.now()+172800000);
        setCookie('wbcAnalyticsCC', false, { path: "/", expires: d })
        setCookie('wbcAdsCC', false, { path: "/", expires: d })
        window.wbcAnalyticsCC = false
        window.wbcAdsCC = false
    }

    React.useEffect(() => {

    },[cookies])

    return(
    <div>
        {temporaryBannerDisable === false && !iniFrame() && (insideMSTeams === false && !cookies.wbcAnalyticsCC &&
        <div className="inclusion-main">
            <Hidden mdUp>
                <div className="contact-form-wrapper justify-content-center">
                    <form action="#" className="cookie-consent-form-mini">
                        <p className="cc-description-mini">We use cookies for application analytics and to display ads on the free plan.
                        We do not sell your personal data.
                        </p>
                        <a className="privacy-link" href="/privacy" target="_blank" rel="noopener noreferrer"> Privacy Details</a>
                        <div className="cc-form-controls-mini">
                        <div className="submit-button-wrapper-mini" onClick={cookiesAccepted}>
                            <input type="submit" value="Accept"/>
                        </div>
                        <div className="submit-button-wrapper-mini" onClick={onlyAnalyticsCookiesAccepted}>
                            <input className="outline" type="submit" value="Necessary Only"/>
                        </div>
                        <IconButton aria-label="delete" onClick={cookiesPanelClosed}>
                            <CloseIcon />
                        </IconButton>
                        </div>
                    </form>
                </div>
            </Hidden>
            <Hidden smDown>
                <div className="contact-form-wrapper justify-content-center">
                    <form action="#" className="cookie-consent-form">
                        <img alt="cookie icon" className="cookieImg" src="/WBCCookie.svg"/>
                        <p className="cc-description">We use cookies for application analytics and to display ads on the free plan.
                        We do not sell your personal data.
                        <a className="privacy-link" href="/privacy" target="_blank" rel="noopener noreferrer"> Privacy Details</a>
                        </p>
                        {/* <div onClick={}>Privacy</div> */}
                        <div className="cc-form-controls">
                        <div className="submit-button-wrapper" onClick={cookiesAccepted}>
                            <input type="submit" value="Accept All"/>
                        </div>
                        <div className="submit-button-wrapper" onClick={onlyAnalyticsCookiesAccepted}>
                            <input className="outline" type="submit" value="Necessary Only"/>
                        </div>
                        {/* <div className="submit-button-wrapper" onClick={customizeCookies}>
                            <input className="outline" type="submit" value="Custom"/>
                        </div> */}
                        <IconButton aria-label="delete" onClick={cookiesPanelClosed}>
                            <CloseIcon />
                        </IconButton>
                        </div>
                    </form>
                </div>
            </Hidden>
        </div>)}
    </div>
    )
}