import React, { Suspense } from 'react';
import { lazyWithRetry } from './lazyWithRetry';
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { CookiesProvider } from 'react-cookie';
import CookieConsent from './CookieConsent';

import { createStore } from 'redux';
import reducer from "./store/reducer"
import * as WBCReactGA from './WBCReactGA';
const HomePage = lazyWithRetry(() => import(/* webpackPrefetch: true, webpackChunkName: "homepage" */'./Home/HomePage'));
const App = lazyWithRetry(() => import(/* webpackPrefetch: true, webpackChunkName: "app" */'./App'));
const store = createStore(reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// WBCReactGA.initialize('UA-169613319-1', { debug: false });

const theme = createMuiTheme({
    palette: {
        primary: purple,
        secondary: {
            main: "#1CB1C4"
        },
    },
    status: {
        danger: 'orange',
    },
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 7,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {
                    'scrollbar-width': 'thin',
                },
                '*::-webkit-scrollbar': {
                    width: '6px',
                    height: '6px',
                },
                '*::-webkit-scrollbar-track': {
                    background: 'white'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'grey',
                    borderRadius: '2px',
                },
            }
        }
    }
});

export default function AppOuter(props) {
    const [cookies] = useCookies(['wbcAnalyticsCC', 'wbcAdsCC']);

    React.useEffect(() => {
        if (cookies.wbcAnalyticsCC && cookies.wbcAnalyticsCC === "true") {
            window.wbcAnalyticsCC = true;
            WBCReactGA.initializeGoogleTagManager();
            WBCReactGA.initializeClarityTagManager();
            WBCReactGA.initializeGA();
        }
    }, [cookies])

    return (
        <div >
            <Provider store={store}>
              <CookiesProvider defaultSetOptions={{ path: '/' }}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route exact path="/" component={HomePage} />
                                <Route component={App} />
                            </Switch>
                        </Suspense>
                    </Router>
                    { console.log("cookies.wbcAnalyticsCC = ", cookies)}
                    {!cookies.wbcAnalyticsCC && <CookieConsent />}
                </ThemeProvider>
              </CookiesProvider>
            </Provider>
        </div >

    );
}
