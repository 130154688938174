import Translations from './translations.json';
import GetLang from '../LangSettings';

var language = "EN";

export function setLangauge() {
    language = GetLang();
    if (!Translations[language]) {
        language = "EN"
    }
}

const Translate = (string) => {
    if ((language && language!=="EN")) {
        const translated = Translations[language][string];
        if (translated) {
            return translated;
        } else {
            return string
        }
    } else {
        return string
    }
};

export default Translate;