import ReactGA from 'react-ga';

export function event(props) {
    if (window.wbcAnalyticsCC) {
        ReactGA.event(props);
    }
}

export function pageview(props) {
    if (window.wbcAnalyticsCC) {
        ReactGA.pageview(props);
    }
}

export function exception(props) {
    if (window.wbcAnalyticsCC) {
        ReactGA.exception(props);
    }
}

// Google Analytics
export function initializeGA() {
    if (window.wbcAnalyticsCC) {
        ReactGA.initialize('UA-169613319-1', { debug: false });
    }
}

// Google Tag Manager
export function initializeGoogleTagManager() {
    console.log("Initializing Google Tag Manager");
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= // eslint-disable-line eqeqeq
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-M8MPR46');
}

export function initializeClarityTagManager() {
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src='https://www.clarity.ms/tag/'+i;
        var y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, 'clarity', 'script', 'kkk0idu6gt');
}