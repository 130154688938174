import * as mylocalStorage from "./mylocalStorage";

const GetLang = (string) => {
    var language = ""
    const lang = window.personalConfig;
    const studLang = window.boardConfig
    const parsedLang = lang && JSON.parse(lang);
    const parsedStudLang = studLang && JSON.parse(studLang);
    if (parsedStudLang && parsedStudLang.sessLang) {
        language = parsedStudLang.sessLang
    } else {
        if (parsedLang && parsedLang.language) {
            language = parsedLang.language
        }
    }
    let ll = mylocalStorage.getItem("language")
    if (ll) language = ll
    if(!language || language === "")
        language = "EN"
    return language;
};

export default GetLang;